import * as React from 'react';
import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses, globalColors } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { SelectBox } from '../../components/selectBox';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { OrderActions } from '../../redux/actions';
import Loading from '../../components/loading';

const getBatchOptions = (order: any) => {
  let options: any[] = [{label: 'Empty', value: 'empty'}]
  let list: any[] = []
  const whPrefrenceMap: any = {
    0: '1st',
    1: '2nd',
    2: '3rd',
  }
  Object.keys(order.warehouseInventory).forEach((warehouse: string, index: number) => {
    options.push({listSubheader: true, label: `${whPrefrenceMap[index]} - ${order.warehouseInventory[warehouse].length > 0 ? order.warehouseInventory[warehouse][0].warehouse : '(none)'}`})
    order.warehouseInventory[warehouse].forEach((batch: any) => {
      options.push({
        label: `lot: ${batch.lotNumber} - qty: ${batch.warehouseQty} - exp: ${batch.expirationDate.split('T')[0]} (${batch.diffDays} days)`,
        value: batch.id
      })
      list.push(batch)
    })
  })
  return {initBatchOptions: options, initBatchList: list}
}

const initBatchSelections = (orderRequest: any) => {
  let initBatchSelections: any[] = [{
    quantity: orderRequest.requestedQuantity,
    batchId: null
  }]

  const batchOptionsList = getBatchOptions(orderRequest).initBatchList
  if (orderRequest.batches && orderRequest.batches.length > 0) {
    initBatchSelections = orderRequest.batches.map((batch: any) => {
      return {
        quantity: batch.quantity,
        batchId: batchOptionsList.find((batchOption: any) => {
          if (batchOption.lotNumber === batch.lot && batchOption.warehouse === batch.warehouse) {
            return true
          }
          return false
        })?.id || null
      }
    })

  }

  if ((!orderRequest.batches || orderRequest.batches.length === 0) && Object.keys(orderRequest.defaultBatch).length > 0) {
    initBatchSelections = [{
      quantity: orderRequest.requestedQuantity,
      batchId: batchOptionsList.find((batchOption: any) => {
        if (batchOption.id === orderRequest.defaultBatch.id) {
          return true
        }
        return false
      }).id || null
    }]
  }

  console.log('INIT BATCH SELECTIONS', initBatchSelections)

  return initBatchSelections
}

const ModifyOrderRequest: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
  const [customerProductsLoading, setCustomerProductsLoading] = React.useState<any>(false)
  const [customerProductsError, setCustomerProductsError] = React.useState<string>('')
  const [customerProduct, setCustomerProduct] = React.useState<any>(null);
  const [orderRequest, setOrderRequest] = React.useState<any>(props.orderRequest)
  const [shippingMethod, setShippingMethod] = React.useState<string>(props.orderRequest.shippingMethod)
  const [shippingMethodError, setShippingMethodError] = React.useState<string>('')
  const [approvedQuantity, setApprovedQuantity] = React.useState<number>(props.orderRequest.approvedQuantity || props.orderRequest.requestedQuantity)
  const [batches, setBatches] = React.useState<any[]>(initBatchSelections(props.orderRequest))
  const [batchErrors, setBatchErrors] = React.useState<any[]>([])

  React.useEffect(() => {
		setCustomerProduct(null)
		setCustomerProductsError('')
		setCustomerProductsLoading(true)
		props.getCustomerProducts({
			customerId: orderRequest.customerId,
			params: [],
			onSuccess: (result: any) => {
			  setCustomerProduct(result.restockRecommendations.find((rr: any) => rr.productId === orderRequest.productId ? true : false))
			  setCustomerProductsLoading(false)
			},
			onError: (error: string) => {
			  setCustomerProductsError(error)
			  setCustomerProductsLoading(false)
			}
		})
	}, [])

	const submit = () => {
    if (!validateForm()) {
      return
    }

    const payload = {
      ...orderRequest,
      approvedQuantity: approvedQuantity,
      shippingMethod: shippingMethod,
      updatedBy: props.authUser.record.email,
      batches: getPayloadBatches(batches),
      customer: undefined,
      product: undefined,
      par: undefined,
      defaultBatch: undefined,
      warehouseInventory: undefined
    }
    props.handleOrderRequestUpdate(payload)
	};

  const getPayloadBatches = (batches: any[]) => {
    const batchList = getBatchOptions(orderRequest).initBatchList

    const payloadBatches = batches
      .filter((batch: any) => batch.batchId ? true : false)
      .map((batch: any) => {
        const batchData = batchList.find((selectedBatchData: any) => batch.batchId === selectedBatchData.id)
        return {
          quantity: batch.quantity,
          lot: batchData.lotNumber,
          expiration: batchData.expirationDate,
          warehouse: batchData.warehouse,
        }
      })
    
    if (!payloadBatches || payloadBatches.length === 0) {
      return []
    } 
    
    return payloadBatches
  }

  const validateForm = () => {
    let isValid = true
    if (!shippingMethod) {
      setShippingMethodError('A shipping method is required.')
      isValid = false
    }

    let batchErrorsCopy = [...batchErrors]
    batches.forEach((batch: any, index: number) => {
      batchErrorsCopy[index] = {quantity: false}
      if (!batch.quantity) {
        batchErrorsCopy[index].quantity = 'Each batch must have a quantity.'
        isValid = false
      }
    })
    setBatchErrors(batchErrorsCopy)

    return isValid
  }


	return (
		<div
			style={{
				...getModalStyle(),
				width: isDesktopOrLaptop ? 'auto' : '90%',
				maxHeight: 600,
				maxWidth: 750,
				overflow: 'scroll',
			}}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={() => props.close(false)}
			>
				<CancelIcon />
			</div>
			<h2>Modify Order Request</h2>
      {customerProductsLoading && <Loading message="" />}
      {customerProductsError && <p style={{ color: 'red' }}>{customerProductsError}</p>}
      {customerProduct &&
        <>
          <Grid container>
            <Grid item xs={6}>
              Customer: <b>({orderRequest.customerId}){orderRequest.customer.customerName}</b>
            </Grid>
            <Grid item xs={6}>
              Product: <b>({orderRequest.productId}){orderRequest.product.productName}</b>
            </Grid>
            <Grid item xs={6}>
              Requested Quantity: <b>{orderRequest.requestedQuantity}</b>
            </Grid>
            <Grid item xs={6}>
              Inventory Status: <b>{customerProduct.inventoryStatus}</b>
            </Grid>
            <Grid item xs={6}>
                <span style={{color: approvedQuantity !== orderRequest.requestedQuantity ? 'red' : undefined}}>Approved Quantity: <b>{approvedQuantity}</b></span>
            </Grid>
          </Grid>
          <div style={{marginTop: 15}} />
          <Grid container>
            <Grid item xs={6}>
              <FormControl variant="outlined">
                <SelectBox
                  style={{ width: 200 }}
                  inputLabel={'Shipping Method'}
                  emptyItemLabel={'Select Shipping Method'}
                  listItems={[
                    { label: 'First Overnight (by 8:30AM)', value: 'First Overnight' },
                    { label: 'Standard Overnight (by 10:30AM)', value: 'Standard Overnight' },
                    { label: 'Saturday Delivery (by 10:30AM)', value: 'Saturday Delivery' },
                  ]}
                  selected={shippingMethod || ''}
                  error={shippingMethodError ? true : false}
                  errorText={shippingMethodError || ''}
                  required
                  onChangeItem={(value: any) => {
                    setShippingMethod(value)
                    setShippingMethodError('')
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div style={{margin: '15px 0'}}><b>Batch Selection</b></div>
            </Grid>
            {batches.map((batch: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" style={{ marginRight: 10 }}>
                        <SelectBox
                          inputLabel={'Select Batch'}
                          style={{ width: 320 }}
                          selected={batch.batchId || 'empty'}
                          listItems={getBatchOptions(orderRequest).initBatchOptions
                            // filter off already selected batches
                            .filter((batchOption: any) => {
                              let batchIsUsed = false
                              if (batchOption.value !== batch.batchId) {
                                batchIsUsed = batches.find((selectedBatch: any) => selectedBatch.batchId === batchOption.value) ? true : false
                              }
                              return batchIsUsed ? false : true 
                            })
                          }
                          onChangeItem={(value: any) => {
                            const selectedBatch = value === 'empty' ? null : value
                            let batchesCopy = [...batches]
                            batchesCopy[index].batchId = selectedBatch
                            if (selectedBatch && selectedBatch.warehouseQty < batches[index].quantity) {
                              const newApprovedQty = (approvedQuantity - batches[index].quantity) + selectedBatch.warehouseQty
                              setApprovedQuantity(newApprovedQty)
                              batchesCopy[index].quantity = selectedBatch.warehouseQty
                            }
                            setBatches(batchesCopy)
                          }}
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl variant="outlined" style={{ marginRight: 10 }}>
                      <TextField
                        variant="outlined"
                        type="number"
                        label="Qty"
                        style={{ width: 100 }}
                        required
                        InputProps={{ inputProps: { min: 1, step: 1, max: batches[index].batch?.warehouseQty  || undefined } }}
                        value={(batch.quantity && batch.quantity >= 1) ? batch.quantity : ''}
                        error={batchErrors[index]?.quantity ? true : false}
                        helperText={batchErrors[index]?.quantity || ''}
                        onChange={event => {
                          const newBatchQty: number = event.target.value ? +event.target.value : 0
                          const approvedQtyDiff: number = (batches[index].quantity || 0) - newBatchQty
                          let newApprovedQty = approvedQuantity
                          if (approvedQtyDiff < 0) {
                            newApprovedQty = approvedQuantity + Math.abs(approvedQtyDiff)
                          }
                          if (approvedQtyDiff > 0) {
                            newApprovedQty = approvedQuantity - Math.abs(approvedQtyDiff)
                          }
                          setApprovedQuantity(newApprovedQty)
                          let batchesCopy = [...batches]
                          batchesCopy[index].quantity = +newBatchQty
                          setBatches(batchesCopy)
                          let batchErrorsCopy = [...batchErrors]
                          batchErrorsCopy[index] && delete batchErrorsCopy[index].quantity
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl style={{ verticalAlign: 'middle', height: (index === 0 || index < batches.length - 1) ? '0' : 'auto' }}>
                      {index === batches.length - 1 && <AddCircleIcon
                      style={{ color: globalColors.FFF_BLUE, cursor: 'pointer' }}
                      onClick={() => {
                        setBatches([...batches, {}])
                      }} />}
                      {index !== 0 && <RemoveCircleIcon
                      style={{ color: 'grey', cursor: 'pointer' }}
                      onClick={() => {
                        let batchesCopy = [...batches]
                        batchesCopy.splice(index, 1)
                        setBatches(batchesCopy)
                        const newApprovedQty = batchesCopy.reduce((prev: number, batch: any) => batch.quantity + prev, 0)
                        setApprovedQuantity(newApprovedQty)
                      }} />}
						        </FormControl>
                  </Grid>
                  <Grid item xs={12}><div style={{marginBottom: 15}}></div></Grid>
                </React.Fragment>
              )
            })}
          </Grid>
          <div>
            <FormControl>
              <Button
                type="button"
                className={commonStyles.searchButton}
                variant="contained"
                color="primary"
                onClick={submit}
              >
                SUBMIT
              </Button>
            </FormControl>
          </div>
        </> 
      }
    </div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants?.tenantsList?.result || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getCustomerProducts: (payload: any) => dispatch(OrderActions.getCustomerProductsForOrder(payload)),
  updateOrderRequests: (payload: any) => dispatch(OrderActions.updateOrderRequests(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModifyOrderRequest);
